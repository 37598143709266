/*-----------------------------------*/
/*--- IMPORT STYLES FOR FASTPAGES ---*/
@import "minima/fastpages-styles";   



/*-----------------------------------*/
/*----- ADD YOUR STYLES BELOW -------*/

// If you want to turn off dark background for syntax highlighting, comment or delete the below line.
@import "minima/fastpages-dracula-highlight"; 

.page-content {
    font-family: "Lora", "Segoe UI", SegoeUI, Roboto, "Segoe WP", "Helvetica Neue", "Helvetica", "Tahoma", "Arial", sans-serif !important;
}